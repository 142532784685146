/**
 * @file 时间线
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

.paddle-index-timeline {
    padding: 80px 0 0 0;

    &-main {
        float: left;
    }

    &-title {
        color: #fefdfd;
        font-size: 38px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 40px;
    }

    &-text {
        font-size: 18px;
        font-weight: 400;
        color: #fefdfd;
        line-height: 28px;
        margin-top: 25px;
    }

    &-btns {
        margin-top: 25px;
    }

    &-btn,
    &-btn:focus {
        background: transparent;
        border: 2px solid #fff;
        border-radius: 0;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        height: 48px;
        letter-spacing: 1.8px;
        line-height: 20px;
        text-align: center;
        width: 146px;
    }

    &-btn:hover {
        background: rgba(255, 255, 255, 0.20);
        border: 2px solid #fff;
        color: #fff;
    }

    &-btn:active {
        background: rgba(255, 255, 255, 0.30);
        border: 2px solid #d0d0d0;
        color: #d0d0d0;
    }

    &-btns > * {
        margin-left: 16px;
    }

    &-btns > *:first-child {
        margin-left: 0;
    }

    &-numbers {
        float: right;
    }

    &-number {
        color: #fefdfd;
        display: inline-block;
        text-align: right;

        &-number {
            font-size: 47px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 54px;
        }

        &-text {
            font-size: 24px;
            line-height: 24px;
            margin-top: 14px;
        }
    }

    &-number + &-number {
        margin-left: 98px;
    }

    &-timeline {
        display: flex;
        margin-top: 84px;
        margin-right: -45px;

        &-item {
            flex: 2 1 auto;
            width: 140px;

            &-title {
                color: rgba(255, 255, 255, .8);
                font-size: 16px;
                letter-spacing: 0;
                line-height: 28px;
            }

            &-line {
                margin-top: 22px;

                &-point {
                    background: #7e85e5;
                    border-radius: 999999px;
                    display: inline-block;
                    float: left;
                    height: 10px;
                    width: 10px;
                }

                &-dotted-line {
                    border-bottom: 1px dotted #fff;
                    height: 5px;
                }
            }

            &:last-of-type &-line-point {
                background: #fff;
                border: 4px solid #7e85e5;
                height: 18px;
                margin-top: -4px;
                width: 18px
            }

            &-text {
                color: rgba(255, 255, 255, .8);
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 20px;
                margin-top: 31px;
                width: 110px;
            }

            &:last-of-type &-line-dotted-line {
                width: 110px;
            }
        }
    }
}
