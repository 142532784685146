/**
 * @file 快速开始组件的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-quick-start {
    @import  (less) '~highlight.js/styles/github-gist.css';
    @import '../../../../../components/markdown-container/style';
    @import './markdown-theme';

    color: #000;
    font-size: 16px;
    margin-top: 35px;

    &-content {
        margin-top: 22px;

        &:last-of-type {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
        }
    }

    &-item {
        align-items: center;
        display: flex;

        & + & {
            margin-top: 15px;
        }

        &-tip {
            font-size: 12px;
            font-weight: 400;

            .ant-tooltip-inner {
                background-color: rgba(0, 0, 0, .6);
                border-radius: 4px;
                line-height: 20px;
                padding: 10px;
            }
        }

        &-title {
            font-weight: 600;
            flex: 0 0 auto;
            width: 80px;
        }

        &-title-tip-icon {
            color: #666;
            font-weight: 300;
            margin-left: 5px;
        }

        &-children {
            flex: 1 1 auto;

            > .ant-btn-group {
                align-items: center;
                display: flex;
                width: 100%;
                flex-wrap: wrap;

                > * {
                    flex: 1 1 auto;
                }
            }

            span > .ant-btn {
                width: 100%;
            }

            .ant-btn,
            span > .ant-btn {
                font-size: 16px;
                height: 48px;

                &.checked {
                    background-color: @primary-color;
                    border-color: @primary-color;
                    color: #fff;
                }

                &:active, &:focus {
                    border-color: @primary-color;
                    font-weight: 500;
                }
            }

            .disabled.ant-btn,
            .disabled .ant-btn {
                // border-color: #e5e5e5;
                // color: #b7b7b7;
                background-color: #fff;
                span {
                    text-decoration-line: line-through;
                    text-decoration-color: #6c6c6d;
                    text-decoration-style: solid;
                    text-decoration-thickness: 2px;
                }
            }

            .ant-btn-group {
                > .ant-btn:first-child:not(:last-child),
                > span:first-child:not(:last-child) > .ant-btn {
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px;
                }

                > .ant-btn:last-child:not(:first-child),
                > span:last-child:not(:first-child) > .ant-btn {
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                }
            }
        }
    }

    &-result {
        padding: 15px 0;

        &-item {
            padding: 15px 0;
            position: relative;
        }

        &-title {
            bottom: 0;
            display: inline-block;
            float: left;
            font-weight: 600;
            left: 0;
            position: absolute;
            top: 0;
            vertical-align: middle;
            width: 128px;

            &:after {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                width: 0;
            }
        }

        &-text {
            border: 1px solid #dadcdf;
            border-radius: 2px;
            font-size: 14px;
            line-height: 40px;
            margin-left: 80px;
            padding: 20px 16px;
        }
    }

    &-suffixtext {
        margin-left: 128px;
        padding: 16px;
    }

    &-tips {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        letter-spacing: 0;
        line-height: 30px;
        margin: 20px 0 0 0;

        a {
            color: @primary-color;
        }
    }

    .ant-btn {
        border-color: rgb(217, 217, 217);
        color: #000;

        &:hover:not(.disabled):not(.checked),
        &:focus:not(.disabled):not(.checked) {
            color: @primary-color;
        }
    }
}
