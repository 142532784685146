/**
 * @file 首页 定制走马灯
 * @author FengGuang(fengguang01@baidu.com)
 */

.ant-carousel {
    .paddle-carousel {
        .slick-dots {
            padding: 0 30px;
            text-align: left;

            li {
                margin: 0 5px;
            }

            li > div {
                cursor: pointer;
                height: 16px;
                margin-top: -6px;
                padding-top: 6px;
            }

            li.slick-active button {
                background: rgba(254, 253, 253, .8);
            }

            li button {
                background: rgba(254, 253, 253, .3);
                width: 20px;
            }

        }

        .slick-dots-bottom {
            bottom: 20px;
        }
    }
}

