/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
@import '../../../../components/vertical-ellipsis/style.css';

.paddle-index-partner {
    margin-top: 40px;

    &-left {
        display: inline-block;
        float: left;
        width: 399px;
    }

    &-card {
        height: 94px;

        & + & {
            margin-top: 20px;
        }

        &-title {
            color: #0b0504;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
        }

        &-text {
            color: #666;
            font-size: 14px;
            font-weight: 400;
            height: 48px;
            letter-spacing: 0;
            line-height: 24px;
            margin-top: 9px;
            text-align: justify;
        }
    }

    &-list {
        margin: 0 -20px -20px 488px;
    }

    &-item {
        border: 1px solid #dadcdf;
        border-radius: 2px;
        height: 94px;
        margin: 0 20px 20px 0;
        padding: 24px 20px;
        position: relative;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);

        &:hover {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
        }

        &-background {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
            z-index: -1;
        }
    }
}
