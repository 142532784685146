/**
 * @file paddle 首页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/global';
@import '../../components/header/style';
@import './components/homeBanner/style.less';
@import './components/introduceList/style.less';
@import '../../components/footer/style';
@import '../../components/right-navigation/style';
@import './components/quick-start/style/index';
@import './components/operator-gird/style';
@import './components/icon-text-list/style';
@import './components/picture-text-card/style';
@import './components/icon-text-card/style';
@import './components/product-list/style';
@import './components/news/style';
@import './components/video-list/style';
@import './components/partner/style';
@import './components/timeline/style';


.paddle-index-page {
}

.paddle-index-content {
    max-width: @main-max-width;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 70px;
}

.paddle-index-context-wrap {
    position: relative;
}

.paddle-index-context-min-h2 {
    color: #000;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 45px;
    margin-top: 54px;
}

.paddle-index-context-min-h3 {
    color: #666;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    margin-top: 10px;

    a {
        color: @primary-color;
        margin-left: 8px;
    }
}

.paddle-index-context-min-h4 {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: 10px;

    a {
        color: @primary-color;
        margin-left: 8px;
    }
}

.paddle-index-context-link-more {
    line-height: 45px;
    position: absolute;
    right: 0;
    top: 0;

    a {
        font-size: 18px;
        font-weight: 400;
        color: #000;
        letter-spacing: 0;
    }

    a:hover,
    a:active,
    a:focus {
        color: @primary-color;
    }

    .anticon-right {
        font-size: 16px;
        transform: scale(.82, .98);
    }
}

.paddle-index-left {
    margin-right: 403px;
}

.paddle-index-right {
    display: inline-block;
    float: right;
    margin-top: -54px;
    max-width: 374px;
}

.paddle-index-product-list-wrap {
    // background: #f5f5f5;
}

.paddle-index-timeline-wrap {
    background: #0011c6;
    background: linear-gradient(0deg, #0011c6 0%, #2932e1 100%);
    color: #fff;
}


@primary-color: #2932E1;