/**
 * @file paddle 首页 视频列表
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-index-video-list {
    margin-top: 40px;
    min-height: 185px;

    &-item {
        border-radius: 2px;
        cursor: pointer;
        height: 185px;
        position: relative;
    }

    &-item-background {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;

        &-mask {
            background: rgba(0, 0, 0, .2);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%, #000 100%);
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
            z-index: 1;
        }
    }

    &-item:hover &-item-background-mask {
        opacity: 0;
    }

    &-item-content {
        bottom: 0;
        color: #fff;
        left: 0;
        padding: 30px;
        position: absolute;
        right: 0;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
    }

    &-item:hover &-item-content {
        opacity: 0;
    }

    &-item-title {
        color: #fefdfd;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
    }

    &-item-tag {
        color: #fefdfd;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 22px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;

        .anticon {
            margin-left: 8px;
        }
    }

    &-item .paddle-video-cover {
        opacity: 0;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
    }

    &-item:hover .paddle-video-cover {
        opacity: 1;
    }
}
