/**
 * @file paddle 首页样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../components/vertical-ellipsis/style.css';
@import '../../components/video-modal/~video.js/dist/video-js.css';
@import '../../components/vertical-ellipsis/style.css';
@import '../../components/vertical-ellipsis/style.css';
html,
body {
  min-width: 1400px;
  margin: 0;
  padding: 0;
}
.page {
  font-size: 14px;
  font-weight: 400;
}
.paddle-context-wrap {
  position: relative;
}
.paddle-context-link-more {
  bottom: 0;
  position: absolute;
  right: 0;
}
.paddle-context-link-more a {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: 26px;
}
.paddle-context-h2 {
  color: #000;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  margin-top: 54px;
}
.paddle-context-h3 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 15px;
}
.paddle-context-h3 a {
  color: #2932E1;
  margin-left: 8px;
}
.paddle-context-h4 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 10px;
}
.paddle-context-h4 a {
  color: #2932E1;
  margin-left: 8px;
}
.clear-both:after {
  content: '';
  display: block;
  line-height: 0;
  clear: both;
}
.link-button {
  border: none;
  display: inline;
  margin: 0;
  font-weight: 500;
  padding: 0;
  color: #2932E1;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.link-button:active,
.link-button:hover {
  text-decoration: none;
  outline: 0;
}
.link-button:hover {
  color: #515eed;
}
.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-head-search-ac {
  transition: all 0.5s cubic-bezier(0.7, 0.3, 0.1, 1);
  width: 300px;
}
.paddle-head-search-ac .ant-input-prefix {
  color: #2932E1;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 0px;
}
.paddle-head-search-ac.ant-select-open {
  width: 800px;
}
.paddle-head-search-ac .ant-input {
  height: 22px;
  line-height: 22px;
}
.paddle-head-search-ac .ant-input-affix-wrapper {
  margin-bottom: 5px;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}
.paddle-head-search-ac .ant-input-affix-wrapper-borderless .ant-input-prefix {
  color: #fff;
}
.paddle-head-search-ac .ant-input-affix-wrapper-focused {
  border-radius: 0 !important;
}
.paddle-head-search-ac .ant-input-borderless {
  color: #fff;
}
.paddle-head-search-ac .ant-input-borderless::-webkit-input-placeholder,
.paddle-head-search-ac .ant-input-borderless::placeholder {
  color: rgba(254, 253, 253, 0.5);
}
.paddle-head-search-ac-overlay {
  padding: 0 0 4px 0;
}
.paddle-head-search-ac-overlay-ellipsis {
  display: inline-block;
  flex: none;
  overflow: hidden;
  color: #666;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.paddle-head-search-ac-overlay .ant-select-item {
  padding: 5px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item,
.paddle-head-search-ac-overlay .ant-select-item a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay .ant-select-item-group {
  padding: 23px 40px 8px 40px;
}
.paddle-head-search-ac-overlay .ant-select-item-group,
.paddle-head-search-ac-overlay .ant-select-item-group a {
  color: #999;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}
.paddle-head-search-ac-overlay-more {
  color: #2932E1;
  padding: 12px 40px;
}
.paddle-head-search-ac-overlay-divider {
  border-top: 1px solid #dadcdf;
  margin: 24px 40px 0 40px;
}
.paddle-head-search-ac-overlay-item {
  align-items: center;
  display: flex;
}
.paddle-head-search-ac-overlay-item-title {
  color: #666;
  display: inline-block;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  white-space: nowrap;
  word-break: break-all;
}
.paddle-head-search-ac-overlay-item-title em {
  color: #000;
  font-weight: 700;
}
.paddle-head-search-ac-overlay-item-divider {
  border-left: 1px solid #dadcdf;
  display: inline-block;
  height: 14px;
}
.paddle-head-search-ac-overlay-item-type {
  padding-left: 18px;
}
.paddle-head-search-ac-overlay-loading-wrap {
  margin: 24px 40px;
}
.paddle-header-menu-popup .ant-select-dropdown {
  border-radius: 0;
}
.paddle-head-search-ac-overlay-type2 .ant-select-item-group,
.paddle-head-search-ac-overlay-type2 .ant-select-item-group a {
  color: #000;
}
.paddle-header-wrap {
  font-size: 14px;
  font-weight: 400;
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 0;
  z-index: 999;
}
.paddle-header {
  font-weight: 400;
  height: 60px;
  margin: 0 30px;
  min-width: 1200px;
  overflow: hidden;
}
.paddle-header-background {
  background-color: #1527c2;
  height: 60px;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateZ(0);
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
  color: #fff;
}
.paddle-header .ant-menu-item-selected a {
  color: #fff;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 60px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  width: 100%;
}
.paddle-header-logo {
  margin-top: 13px;
  width: 96px;
}
.paddle-header-menu {
  display: inline-block;
  padding: 16px 0 16px 33px;
  vertical-align: top;
  min-width: 697px;
}
.paddle-header-right {
  float: right;
  height: 60px;
}
.paddle-header-right-feedback {
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
}
.paddle-header-right-feedback-close {
  position: relative;
  top: 7px;
  left: calc(100% - 22px);
}
.paddle-header-right-feedback-close .feedback-close-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;
  background-image: url('../../images/headerfeedback/icon-close.png');
  background-size: cover;
}
.paddle-header-right-feedback-button {
  cursor: pointer;
  position: absolute;
  width: 92px;
  height: 32px;
  left: 10%;
  top: 50%;
  background-image: url('../../images/headerfeedback/button.png');
  background-size: cover;
}
.paddle-header-right-feedback-button span {
  margin-left: 10px;
  text-align: center;
  font-size: 12.6px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 40px;
}
.paddle-header-right-feedback-popover .ant-popover-content {
  position: relative;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 400;
  margin-top: 4px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-arrow {
  top: -4px;
  border: #2932e1;
  background-color: #2932e1;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner {
  width: 314px;
  height: 116px;
  background-image: url('../../images/headerfeedback/bg.png');
  background-size: cover;
  border-radius: 0px;
}
.paddle-header-right-feedback-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  border-bottom: 0px;
}
.paddle-header-right-feedback-icon {
  width: 40px;
  height: 40px;
}
.paddle-header-right-language {
  display: inline-block;
  margin-left: 20px;
  color: #fff;
}
.paddle-header-right-language span {
  margin: 0 5px;
  cursor: pointer;
}
.paddle-header-right-language span:hover {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-zh {
  color: #fff;
  font-weight: 500;
}
.paddle-header-right-language-en {
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
}
.paddle-header-search-wrap {
  display: inline-block;
  line-height: 60px;
  margin-right: 10px;
  width: 280px;
  vertical-align: top;
}
.paddle-header-links-menu {
  display: inline-block;
  height: 60px;
  margin: 0 -17px 0 17px;
  padding: 16px 0 16px 0;
  vertical-align: top;
}
.paddle-header .ant-avatar .anticon {
  margin-right: 0;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 14px;
  padding: 0;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu:nth-last-child(1) {
  margin: 0 16px;
  padding: 0;
}
.paddle-header .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline),
.paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-item:hover > a,
.paddle-header .ant-menu-item-active > a,
.paddle-header .ant-menu:not(.ant-menu-inline) > a,
.paddle-header .ant-menu-submenu-open > a,
.paddle-header .ant-menu-submenu-active > a,
.paddle-header .ant-menu-submenu-title > a,
.paddle-header .ant-menu-submenu-title:hover > a {
  color: #fff;
}
.paddle-header .ant-menu-item,
.paddle-header .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-item.paddle-header-user-avatar-menu-item,
.paddle-header .ant-menu-submenu.paddle-header-user-avatar-menu-item {
  border-bottom: 3px solid transparent;
  height: 27px;
  margin-top: -2px;
  vertical-align: top;
}
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.paddle-header .ant-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 600;
}
.paddle-header .ant-menu-item a {
  color: #fff;
}
.paddle-header .ant-menu-item a:hover {
  color: #fff;
}
.paddle-header .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
}
.paddle-header-item-group-list .ant-menu-item,
.paddle-header-item-group-list .ant-menu-submenu-title {
  padding: 0;
  margin: 0;
  height: 27px;
}
.paddle-header-menu-popup {
  position: static;
  position: sticky;
  transform: translateZ(0);
  top: 60px;
  z-index: 1499;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item {
  font-weight: 500;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-active {
  background: #f7f7f7;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item,
.paddle-header-menu-popup .ant-menu .ant-menu-submenu {
  margin: 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-item,
.paddle-header-menu-popup .ant-menu.ant-menu-sub .ant-menu-submenu {
  padding: 0 20px;
}
.paddle-header-menu-popup .ant-menu .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header-submenu-popup-large {
  max-width: 100%;
  right: 0;
}
.paddle-header-submenu-popup-large .ant-menu {
  display: flex;
  padding: 22px 0 42px 55px;
  text-align: left;
}
.paddle-header-submenu-popup-large .ant-menu > * {
  flex-grow: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group {
  float: left;
  padding: 0 15px;
  width: 225px;
  z-index: 1;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-title {
  border-bottom: 1px solid #e9eaec;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 42px;
  margin: 0 10px;
  padding: 0;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item-group-list {
  margin-top: 12px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item {
  color: #333;
  font-weight: 500;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0 10px;
}
.paddle-header-submenu-popup-large .ant-menu .ant-menu-item:hover {
  background: #f7f7f7;
  color: #1434c2;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title {
  width: 315px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-title {
  display: none;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item {
  font-size: 16px;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover),
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item:not(:hover) > a {
  color: #000;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-no-title .ant-menu-item-group-list {
  margin-top: 0;
}
.paddle-header-submenu-popup-large .ant-menu .paddle-header-submenu-popup-group-right-icon {
  margin-left: 10px;
  transform: scale(0.65, 0.8);
}
.paddle-header-submenu-popup-large .ant-menu-hidden {
  display: none;
}
.paddle-header-submenu-popup-large-tag {
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}
.paddle-header-submenu-popup-large-tag.red {
  color: #ff3912;
}
.paddle-header-submenu-popup-large-tag.blue {
  color: #1a73e8;
}
.paddle-header-submenu-popup-large-tag.green {
  color: #00b359;
}
.paddle-header-submenu-popup-large-bg {
  background: #fff;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 21%;
  z-index: 0;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 60px;
  height: 60px;
  margin-left: -2px;
}
.paddle-header-links-link:after {
  display: block;
  content: attr(title);
  font-weight: 500;
  visibility: hidden;
  height: 0;
  margin-bottom: -1px;
  overflow: hidden;
}
@media screen and (max-width: 1260px) {
  .paddle-header {
    margin: 0 auto;
    width: 1400px;
  }
}
/**
 * @file 首页banner样式
 */
.paddle-home-banner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 200px;
  max-width: 1400px;
  margin: 0 auto;
}
.paddle-home-banner-wrap {
  margin-bottom: 32px;
  width: 100%;
  height: 350px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  .paddle-home-banner-wrap {
    height: 450px;
  }
}
.paddle-home-banner-title {
  font-size: 45px;
  line-height: 56px;
  font-weight: 500;
  color: #2932E1;
  letter-spacing: 1px;
}
.paddle-home-banner-subtitle {
  margin-top: 10px;
  color: #140e35;
  font-size: 18px;
  letter-spacing: 4px;
}
.paddle-home-banner-btn {
  display: inline-block;
  margin-top: 28px;
  height: 40px;
  padding: 4px 24px;
  font-size: 17px;
  line-height: 32px;
  color: #fff;
  background: rgba(70, 88, 255, 0.9);
}
.paddle-home-banner-btn:hover {
  color: #fff;
  background: #4658ff;
}
.paddle-home-introduce {
  display: flex;
}
.paddle-home-introduce-card {
  width: 20%;
  padding: 12px 24px;
  margin-right: 16px;
  cursor: pointer;
}
.paddle-home-introduce-card:hover {
  box-shadow: 4px 0px 6px 4px rgba(0, 0, 0, 0.05);
}
.paddle-home-introduce-card:last-child {
  margin-right: 0;
}
.paddle-home-introduce-card-title {
  margin-bottom: 12px;
  font-size: 18px;
  color: #000;
}
.paddle-home-introduce-card-desc {
  font-size: 14px;
  line-height: 28px;
  color: #565772;
}
/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #000;
  display: block;
  min-height: 403px;
}
.paddle-footer,
.paddle-footer a {
  color: #999;
}
.paddle-footer-container {
  max-width: 1400px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 85px 0;
}
.paddle-footer-group {
  width: 191px;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  font-size: 18px;
}
.paddle-footer-item-list {
  float: left;
  margin-top: 25px;
}
.paddle-footer-item,
.paddle-footer-item a {
  color: #f0f0f0;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
}
.paddle-footer-qr-wrap {
  float: right;
  margin-bottom: -11px;
  margin-right: -55px;
}
.paddle-footer-qr-item {
  display: inline-block;
  margin-right: 55px;
  vertical-align: top;
  width: 180px;
  text-align: center;
}
.paddle-footer-qr-context {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 140px;
  overflow: hidden;
  width: 140px;
}
.paddle-footer-qr-context-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-title {
  color: #7b7b7b;
  font-size: 14px;
  line-height: 14px;
  margin-top: 14px;
  text-align: center;
  vertical-align: top;
}
.paddle-footer-qr-subtitle {
  font-size: 12px;
}
.paddle-footer-bottom {
  background: #000;
  border-top: 1px solid #242424;
  color: #999;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.paddle-footer .beian {
  margin-left: 100px;
}
.paddle-footer .footer-mark-number {
  margin-left: 80px;
}
.paddle-footer .record-link-style:hover {
  color: rgba(153, 153, 153, 0.8);
}
/**
 * @file 右侧导航条样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-right-nav {
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  top: 50%;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1000;
}
.paddle-right-nav-item {
  cursor: pointer;
  height: 56px;
  position: relative;
  width: 50px;
}
.paddle-right-nav-item-background {
  background-repeat: no-repeat;
  background-size: 24px auto;
  background-position: center center;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1;
}
.paddle-right-nav-item-context {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  bottom: 0;
  left: 0;
  letter-spacing: 1px;
  line-height: 16px;
  opacity: 0;
  padding: 13px 10px;
  position: absolute;
  right: 0;
  text-align: justify;
  top: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 2;
}
.paddle-right-nav-item-havecontent:hover .paddle-right-nav-item-background {
  opacity: 0;
}
.paddle-right-nav-item-havecontent:hover .paddle-right-nav-item-context {
  opacity: 1;
}
.paddle-right-nav-item:hover {
  background-color: #f7f7f7;
}
.paddle-right-nav-item-consultation .paddle-right-nav-item-background {
  background-image: url(../../components/right-navigation/image/mailbox.png);
}
.paddle-right-nav-item-qrcode .paddle-right-nav-item-background {
  background-image: url(../../components/right-navigation/image/qrcodeicon.png);
}
.paddle-right-nav-item-top .paddle-right-nav-item-background {
  background-image: url(../../components/right-navigation/image/up.png);
  background-size: 17px auto;
}
.paddle-right-nav-item-top {
  height: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-right-nav-item-top-show {
  height: 56px;
}
.paddle-right-nav-item-qrcode-title {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  padding: 10px 0 8px 0;
  text-align: center;
}
.paddle-right-nav-item-qrcode-qrcode {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: 1px solid #f0f0f0;
  height: 148px;
  width: 148px;
}
.paddle-right-nav-item-qrcode-overlay .ant-popover-inner-content {
  padding: 0 6px 10px 6px;
}
/**
 * @file 快速开始组件的样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-quick-start {
  /**
 * GitHub Gist Theme
 * Author : Anthony Attard - https://github.com/AnthonyAttard
 * Author : Louis Barranqueiro - https://github.com/LouisBarranqueiro
 */
  /**
 * @file Markdown 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
  /**
 * @file Markdown 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
  color: #000;
  font-size: 16px;
  margin-top: 35px;
}
.paddle-quick-start .hljs {
  display: block;
  background: white;
  padding: 0.5em;
  color: #333333;
  overflow-x: auto;
}
.paddle-quick-start .hljs-comment,
.paddle-quick-start .hljs-meta {
  color: #969896;
}
.paddle-quick-start .hljs-variable,
.paddle-quick-start .hljs-template-variable,
.paddle-quick-start .hljs-strong,
.paddle-quick-start .hljs-emphasis,
.paddle-quick-start .hljs-quote {
  color: #df5000;
}
.paddle-quick-start .hljs-keyword,
.paddle-quick-start .hljs-selector-tag,
.paddle-quick-start .hljs-type {
  color: #d73a49;
}
.paddle-quick-start .hljs-literal,
.paddle-quick-start .hljs-symbol,
.paddle-quick-start .hljs-bullet,
.paddle-quick-start .hljs-attribute {
  color: #0086b3;
}
.paddle-quick-start .hljs-section,
.paddle-quick-start .hljs-name {
  color: #63a35c;
}
.paddle-quick-start .hljs-tag {
  color: #333333;
}
.paddle-quick-start .hljs-title,
.paddle-quick-start .hljs-attr,
.paddle-quick-start .hljs-selector-id,
.paddle-quick-start .hljs-selector-class,
.paddle-quick-start .hljs-selector-attr,
.paddle-quick-start .hljs-selector-pseudo {
  color: #6f42c1;
}
.paddle-quick-start .hljs-addition {
  color: #55a532;
  background-color: #eaffea;
}
.paddle-quick-start .hljs-deletion {
  color: #bd2c00;
  background-color: #ffecec;
}
.paddle-quick-start .hljs-link {
  text-decoration: underline;
}
.paddle-quick-start .hljs-number {
  color: #005cc5;
}
.paddle-quick-start .hljs-string {
  color: #032f62;
}
.paddle-quick-start .markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: #24292e;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
}
.paddle-quick-start .markdown-body .pl-c {
  color: #6a737d;
}
.paddle-quick-start .markdown-body .pl-c1,
.paddle-quick-start .markdown-body .pl-s .pl-v {
  color: #005cc5;
}
.paddle-quick-start .markdown-body .pl-e,
.paddle-quick-start .markdown-body .pl-en {
  color: #6f42c1;
}
.paddle-quick-start .markdown-body .pl-smi,
.paddle-quick-start .markdown-body .pl-s .pl-s1 {
  color: #24292e;
}
.paddle-quick-start .markdown-body .pl-ent {
  color: #22863a;
}
.paddle-quick-start .markdown-body .pl-k {
  color: #d73a49;
}
.paddle-quick-start .markdown-body .pl-s,
.paddle-quick-start .markdown-body .pl-pds,
.paddle-quick-start .markdown-body .pl-s .pl-pse .pl-s1,
.paddle-quick-start .markdown-body .pl-sr,
.paddle-quick-start .markdown-body .pl-sr .pl-cce,
.paddle-quick-start .markdown-body .pl-sr .pl-sre,
.paddle-quick-start .markdown-body .pl-sr .pl-sra {
  color: #032f62;
}
.paddle-quick-start .markdown-body .pl-v,
.paddle-quick-start .markdown-body .pl-smw {
  color: #e36209;
}
.paddle-quick-start .markdown-body .pl-bu {
  color: #b31d28;
}
.paddle-quick-start .markdown-body .pl-ii {
  color: #fafbfc;
  background-color: #b31d28;
}
.paddle-quick-start .markdown-body .pl-c2 {
  color: #fafbfc;
  background-color: #d73a49;
}
.paddle-quick-start .markdown-body .pl-c2::before {
  content: "^M";
}
.paddle-quick-start .markdown-body .pl-sr .pl-cce {
  font-weight: bold;
  color: #22863a;
}
.paddle-quick-start .markdown-body .pl-ml {
  color: #735c0f;
}
.paddle-quick-start .markdown-body .pl-mh,
.paddle-quick-start .markdown-body .pl-mh .pl-en,
.paddle-quick-start .markdown-body .pl-ms {
  font-weight: bold;
  color: #005cc5;
}
.paddle-quick-start .markdown-body .pl-mi {
  font-style: italic;
  color: #24292e;
}
.paddle-quick-start .markdown-body .pl-mb {
  font-weight: bold;
  color: #24292e;
}
.paddle-quick-start .markdown-body .pl-md {
  color: #b31d28;
  background-color: #ffeef0;
}
.paddle-quick-start .markdown-body .pl-mi1 {
  color: #22863a;
  background-color: #f0fff4;
}
.paddle-quick-start .markdown-body .pl-mc {
  color: #e36209;
  background-color: #ffebda;
}
.paddle-quick-start .markdown-body .pl-mi2 {
  color: #f6f8fa;
  background-color: #005cc5;
}
.paddle-quick-start .markdown-body .pl-mdr {
  font-weight: bold;
  color: #6f42c1;
}
.paddle-quick-start .markdown-body .pl-ba {
  color: #586069;
}
.paddle-quick-start .markdown-body .pl-sg {
  color: #959da5;
}
.paddle-quick-start .markdown-body .pl-corl {
  text-decoration: underline;
  color: #032f62;
}
.paddle-quick-start .markdown-body .octicon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
}
.paddle-quick-start .markdown-body a {
  background-color: transparent;
}
.paddle-quick-start .markdown-body a:active,
.paddle-quick-start .markdown-body a:hover {
  outline-width: 0;
}
.paddle-quick-start .markdown-body strong {
  font-weight: inherit;
}
.paddle-quick-start .markdown-body strong {
  font-weight: bolder;
}
.paddle-quick-start .markdown-body h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
.paddle-quick-start .markdown-body img {
  border-style: none;
}
.paddle-quick-start .markdown-body code,
.paddle-quick-start .markdown-body kbd,
.paddle-quick-start .markdown-body pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
.paddle-quick-start .markdown-body hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
.paddle-quick-start .markdown-body input {
  font: inherit;
  margin: 0;
}
.paddle-quick-start .markdown-body input {
  overflow: visible;
}
.paddle-quick-start .markdown-body [type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.paddle-quick-start .markdown-body * {
  box-sizing: border-box;
}
.paddle-quick-start .markdown-body input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.paddle-quick-start .markdown-body a {
  color: #0366d6;
  text-decoration: none;
}
.paddle-quick-start .markdown-body a:hover {
  text-decoration: underline;
}
.paddle-quick-start .markdown-body strong {
  font-weight: 600;
}
.paddle-quick-start .markdown-body hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dfe2e5;
}
.paddle-quick-start .markdown-body hr::before {
  display: table;
  content: "";
}
.paddle-quick-start .markdown-body hr::after {
  display: table;
  clear: both;
  content: "";
}
.paddle-quick-start .markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
}
.paddle-quick-start .markdown-body td,
.paddle-quick-start .markdown-body th {
  padding: 0;
}
.paddle-quick-start .markdown-body h1,
.paddle-quick-start .markdown-body h2,
.paddle-quick-start .markdown-body h3,
.paddle-quick-start .markdown-body h4,
.paddle-quick-start .markdown-body h5,
.paddle-quick-start .markdown-body h6 {
  margin-top: 0;
  margin-bottom: 0;
}
.paddle-quick-start .markdown-body h1 {
  font-size: 32px;
  font-weight: 600;
}
.paddle-quick-start .markdown-body h2 {
  font-size: 24px;
  font-weight: 600;
}
.paddle-quick-start .markdown-body h3 {
  font-size: 20px;
  font-weight: 600;
}
.paddle-quick-start .markdown-body h4 {
  font-size: 16px;
  font-weight: 600;
}
.paddle-quick-start .markdown-body h5 {
  font-size: 14px;
  font-weight: 600;
}
.paddle-quick-start .markdown-body h6 {
  font-size: 12px;
  font-weight: 600;
}
.paddle-quick-start .markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}
.paddle-quick-start .markdown-body blockquote {
  margin: 0;
}
.paddle-quick-start .markdown-body ul,
.paddle-quick-start .markdown-body ol {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.paddle-quick-start .markdown-body ol ol,
.paddle-quick-start .markdown-body ul ol {
  list-style-type: lower-roman;
}
.paddle-quick-start .markdown-body ul ul ol,
.paddle-quick-start .markdown-body ul ol ol,
.paddle-quick-start .markdown-body ol ul ol,
.paddle-quick-start .markdown-body ol ol ol {
  list-style-type: lower-alpha;
}
.paddle-quick-start .markdown-body dd {
  margin-left: 0;
}
.paddle-quick-start .markdown-body code {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
}
.paddle-quick-start .markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 12px;
}
.paddle-quick-start .markdown-body .octicon {
  vertical-align: text-bottom;
}
.paddle-quick-start .markdown-body .pl-0 {
  padding-left: 0 !important;
}
.paddle-quick-start .markdown-body .pl-1 {
  padding-left: 4px !important;
}
.paddle-quick-start .markdown-body .pl-2 {
  padding-left: 8px !important;
}
.paddle-quick-start .markdown-body .pl-3 {
  padding-left: 16px !important;
}
.paddle-quick-start .markdown-body .pl-4 {
  padding-left: 24px !important;
}
.paddle-quick-start .markdown-body .pl-5 {
  padding-left: 32px !important;
}
.paddle-quick-start .markdown-body .pl-6 {
  padding-left: 40px !important;
}
.paddle-quick-start .markdown-body::before {
  display: table;
  content: "";
}
.paddle-quick-start .markdown-body::after {
  display: table;
  clear: both;
  content: "";
}
.paddle-quick-start .markdown-body > *:first-child {
  margin-top: 0 !important;
}
.paddle-quick-start .markdown-body > *:last-child {
  margin-bottom: 0 !important;
}
.paddle-quick-start .markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}
.paddle-quick-start .markdown-body .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.paddle-quick-start .markdown-body .anchor:focus {
  outline: none;
}
.paddle-quick-start .markdown-body p,
.paddle-quick-start .markdown-body blockquote,
.paddle-quick-start .markdown-body ul,
.paddle-quick-start .markdown-body ol,
.paddle-quick-start .markdown-body dl,
.paddle-quick-start .markdown-body table,
.paddle-quick-start .markdown-body pre {
  margin-top: 0;
  margin-bottom: 16px;
}
.paddle-quick-start .markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}
.paddle-quick-start .markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #dfe2e5;
}
.paddle-quick-start .markdown-body blockquote > :first-child {
  margin-top: 0;
}
.paddle-quick-start .markdown-body blockquote > :last-child {
  margin-bottom: 0;
}
.paddle-quick-start .markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #c6cbd1;
  border-bottom-color: #959da5;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #959da5;
}
.paddle-quick-start .markdown-body h1,
.paddle-quick-start .markdown-body h2,
.paddle-quick-start .markdown-body h3,
.paddle-quick-start .markdown-body h4,
.paddle-quick-start .markdown-body h5,
.paddle-quick-start .markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}
.paddle-quick-start .markdown-body h1 .octicon-link,
.paddle-quick-start .markdown-body h2 .octicon-link,
.paddle-quick-start .markdown-body h3 .octicon-link,
.paddle-quick-start .markdown-body h4 .octicon-link,
.paddle-quick-start .markdown-body h5 .octicon-link,
.paddle-quick-start .markdown-body h6 .octicon-link {
  color: #1b1f23;
  vertical-align: middle;
  visibility: hidden;
}
.paddle-quick-start .markdown-body h1:hover .anchor,
.paddle-quick-start .markdown-body h2:hover .anchor,
.paddle-quick-start .markdown-body h3:hover .anchor,
.paddle-quick-start .markdown-body h4:hover .anchor,
.paddle-quick-start .markdown-body h5:hover .anchor,
.paddle-quick-start .markdown-body h6:hover .anchor {
  text-decoration: none;
}
.paddle-quick-start .markdown-body h1:hover .anchor .octicon-link,
.paddle-quick-start .markdown-body h2:hover .anchor .octicon-link,
.paddle-quick-start .markdown-body h3:hover .anchor .octicon-link,
.paddle-quick-start .markdown-body h4:hover .anchor .octicon-link,
.paddle-quick-start .markdown-body h5:hover .anchor .octicon-link,
.paddle-quick-start .markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}
.paddle-quick-start .markdown-body h1 {
  padding-bottom: 0.3em;
  font-size: 2em;
  border-bottom: 1px solid #eaecef;
}
.paddle-quick-start .markdown-body h2 {
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border-bottom: 1px solid #eaecef;
}
.paddle-quick-start .markdown-body h3 {
  font-size: 1.25em;
}
.paddle-quick-start .markdown-body h4 {
  font-size: 1em;
}
.paddle-quick-start .markdown-body h5 {
  font-size: 0.875em;
}
.paddle-quick-start .markdown-body h6 {
  font-size: 0.85em;
  color: #6a737d;
}
.paddle-quick-start .markdown-body ul,
.paddle-quick-start .markdown-body ol {
  padding-left: 2em;
}
.paddle-quick-start .markdown-body ul ul,
.paddle-quick-start .markdown-body ul ol,
.paddle-quick-start .markdown-body ol ol,
.paddle-quick-start .markdown-body ol ul {
  margin-top: 0;
  margin-bottom: 0;
}
.paddle-quick-start .markdown-body li {
  word-wrap: break-all;
}
.paddle-quick-start .markdown-body li > p {
  margin-top: 16px;
}
.paddle-quick-start .markdown-body li + li {
  margin-top: 0.25em;
}
.paddle-quick-start .markdown-body dl {
  padding: 0;
}
.paddle-quick-start .markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}
.paddle-quick-start .markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}
.paddle-quick-start .markdown-body table {
  display: block;
  width: 100%;
  overflow: auto;
}
.paddle-quick-start .markdown-body table th {
  font-weight: 600;
}
.paddle-quick-start .markdown-body table th,
.paddle-quick-start .markdown-body table td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}
.paddle-quick-start .markdown-body table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}
.paddle-quick-start .markdown-body table tr:nth-child(2n) {
  background-color: #f6f8fa;
}
.paddle-quick-start .markdown-body img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
}
.paddle-quick-start .markdown-body img[align=right] {
  padding-left: 20px;
}
.paddle-quick-start .markdown-body img[align=left] {
  padding-right: 20px;
}
.paddle-quick-start .markdown-body code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}
.paddle-quick-start .markdown-body pre {
  word-wrap: normal;
}
.paddle-quick-start .markdown-body pre > code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}
.paddle-quick-start .markdown-body .highlight {
  margin-bottom: 16px;
}
.paddle-quick-start .markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}
.paddle-quick-start .markdown-body .highlight pre,
.paddle-quick-start .markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}
.paddle-quick-start .markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}
.paddle-quick-start .markdown-body .full-commit .btn-outline:not(:disabled):hover {
  color: #005cc5;
  border-color: #005cc5;
}
.paddle-quick-start .markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 10px;
  color: #444d56;
  vertical-align: middle;
  background-color: #fafbfc;
  border: solid 1px #d1d5da;
  border-bottom-color: #c6cbd1;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #c6cbd1;
}
.paddle-quick-start .markdown-body :checked + .radio-label {
  position: relative;
  z-index: 1;
  border-color: #0366d6;
}
.paddle-quick-start .markdown-body .task-list-item {
  list-style-type: none;
}
.paddle-quick-start .markdown-body .task-list-item + .task-list-item {
  margin-top: 3px;
}
.paddle-quick-start .markdown-body .task-list-item input {
  margin: 0 0.2em 0.25em -1.6em;
  vertical-align: middle;
}
.paddle-quick-start .markdown-body hr {
  border-bottom-color: #eee;
}
.paddle-quick-start .markdown-body {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
}
.paddle-quick-start .markdown-body a {
  color: #2932E1;
}
.paddle-quick-start .markdown-body code {
  background: #f5f5f5;
  border-radius: 2px;
  display: inline-block;
  font-size: 100%;
  padding: 8px 16px;
  position: relative;
}
.paddle-quick-start .markdown-body code .copy-btn {
  right: 10px;
  top: 2px;
}
.paddle-quick-start .markdown-body .highlight pre,
.paddle-quick-start .markdown-body pre {
  background: #f5f5f5;
  border-radius: 2px;
  margin-top: 10px;
  min-width: 0;
  padding: 16px 24px 16px 16px;
  position: relative;
  width: auto;
}
.paddle-quick-start .markdown-body .highlight pre code,
.paddle-quick-start .markdown-body pre code,
.paddle-quick-start .markdown-body .highlight pre code *,
.paddle-quick-start .markdown-body pre code * {
  border: 0;
  min-width: 0;
  padding-right: 0;
  width: auto;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #333;
}
.paddle-quick-start .markdown-body .highlight pre .copy-btn,
.paddle-quick-start .markdown-body pre .copy-btn {
  right: 10px;
  top: 18px;
}
.paddle-quick-start .markdown-body .copy-btn {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(./components/quick-start/image/copy.png);
  cursor: pointer;
  float: right;
  display: inline-block;
  height: 12px;
  position: absolute;
  width: 12px;
}
.paddle-quick-start .markdown-body .copy-btn:hover {
  background-image: url(./components/quick-start/image/copy.png);
}
.paddle-quick-start-content {
  margin-top: 22px;
}
.paddle-quick-start-content:last-of-type {
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}
.paddle-quick-start-item {
  align-items: center;
  display: flex;
}
.paddle-quick-start-item + .paddle-quick-start-item {
  margin-top: 15px;
}
.paddle-quick-start-item-tip {
  font-size: 12px;
  font-weight: 400;
}
.paddle-quick-start-item-tip .ant-tooltip-inner {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  line-height: 20px;
  padding: 10px;
}
.paddle-quick-start-item-title {
  font-weight: 600;
  flex: 0 0 auto;
  width: 80px;
}
.paddle-quick-start-item-title-tip-icon {
  color: #666;
  font-weight: 300;
  margin-left: 5px;
}
.paddle-quick-start-item-children {
  flex: 1 1 auto;
}
.paddle-quick-start-item-children > .ant-btn-group {
  align-items: center;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.paddle-quick-start-item-children > .ant-btn-group > * {
  flex: 1 1 auto;
}
.paddle-quick-start-item-children span > .ant-btn {
  width: 100%;
}
.paddle-quick-start-item-children .ant-btn,
.paddle-quick-start-item-children span > .ant-btn {
  font-size: 16px;
  height: 48px;
}
.paddle-quick-start-item-children .ant-btn.checked,
.paddle-quick-start-item-children span > .ant-btn.checked {
  background-color: #2932E1;
  border-color: #2932E1;
  color: #fff;
}
.paddle-quick-start-item-children .ant-btn:active,
.paddle-quick-start-item-children span > .ant-btn:active,
.paddle-quick-start-item-children .ant-btn:focus,
.paddle-quick-start-item-children span > .ant-btn:focus {
  border-color: #2932E1;
  font-weight: 500;
}
.paddle-quick-start-item-children .disabled.ant-btn,
.paddle-quick-start-item-children .disabled .ant-btn {
  background-color: #fff;
}
.paddle-quick-start-item-children .disabled.ant-btn span,
.paddle-quick-start-item-children .disabled .ant-btn span {
  text-decoration-line: line-through;
  text-decoration-color: #6c6c6d;
  text-decoration-style: solid;
  text-decoration-thickness: 2px;
}
.paddle-quick-start-item-children .ant-btn-group > .ant-btn:first-child:not(:last-child),
.paddle-quick-start-item-children .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.paddle-quick-start-item-children .ant-btn-group > .ant-btn:last-child:not(:first-child),
.paddle-quick-start-item-children .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.paddle-quick-start-result {
  padding: 15px 0;
}
.paddle-quick-start-result-item {
  padding: 15px 0;
  position: relative;
}
.paddle-quick-start-result-title {
  bottom: 0;
  display: inline-block;
  float: left;
  font-weight: 600;
  left: 0;
  position: absolute;
  top: 0;
  vertical-align: middle;
  width: 128px;
}
.paddle-quick-start-result-title:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.paddle-quick-start-result-text {
  border: 1px solid #dadcdf;
  border-radius: 2px;
  font-size: 14px;
  line-height: 40px;
  margin-left: 80px;
  padding: 20px 16px;
}
.paddle-quick-start-suffixtext {
  margin-left: 128px;
  padding: 16px;
}
.paddle-quick-start-tips {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  letter-spacing: 0;
  line-height: 30px;
  margin: 20px 0 0 0;
}
.paddle-quick-start-tips a {
  color: #2932E1;
}
.paddle-quick-start .ant-btn {
  border-color: #d9d9d9;
  color: #000;
}
.paddle-quick-start .ant-btn:hover:not(.disabled):not(.checked),
.paddle-quick-start .ant-btn:focus:not(.disabled):not(.checked) {
  color: #2932E1;
}
/**
 * @file 首页 运营位样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 首页 定制走马灯
 * @author FengGuang(fengguang01@baidu.com)
 */
.ant-carousel .paddle-carousel .slick-dots {
  padding: 0 30px;
  text-align: left;
}
.ant-carousel .paddle-carousel .slick-dots li {
  margin: 0 5px;
}
.ant-carousel .paddle-carousel .slick-dots li > div {
  cursor: pointer;
  height: 16px;
  margin-top: -6px;
  padding-top: 6px;
}
.ant-carousel .paddle-carousel .slick-dots li.slick-active button {
  background: rgba(254, 253, 253, 0.8);
}
.ant-carousel .paddle-carousel .slick-dots li button {
  background: rgba(254, 253, 253, 0.3);
  width: 20px;
}
.ant-carousel .paddle-carousel .slick-dots-bottom {
  bottom: 20px;
}
/**
 * @file 视频封面
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-video-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.paddle-video-cover-hover-autoplay {
  cursor: pointer;
}
/**
 * @file 视频弹窗
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-video-modal-wrap {
  text-align: center;
}
.paddle-video-modal-wrap:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.paddle-video-modal-wrap .ant-modal-close-icon {
  background-color: rgba(255, 255, 255, 0.2);
}
.video-js {
  height: 100%;
  width: 100%;
}
.video-js + .video-js {
  display: none;
}
.paddle-video-modal {
  display: inline-block;
  max-width: 960px;
  padding: 0;
  top: 0;
  vertical-align: middle;
}
.paddle-video-modal-player-wrap {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
.paddle-video-modal-player-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-video-modal-player {
  height: 100%;
  width: 100%;
}
.paddle-video-modal-player * {
  outline: none;
}
.paddle-video-modal .ant-modal-content {
  background-color: transparent;
}
.paddle-video-modal .ant-modal-body {
  padding: 0;
}
.paddle-operator-gird-carousel-wrap {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  display: inline-block;
  float: left;
  height: 330px;
  width: 270px;
}
.paddle-operator-gird-carousel .slick-slide {
  height: 330px;
  overflow: hidden;
  position: relative;
}
.paddle-operator-gird-carousel-link {
  cursor: pointer;
}
.paddle-operator-gird-carousel-content {
  bottom: 30px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-operator-gird-carousel-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.paddle-operator-gird-carousel-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: 34px;
  padding: 0 25px;
}
.paddle-operator-gird-carousel-text {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
  line-height: 28px;
  padding: 0 25px;
}
.paddle-operator-gird-carousel-other {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  padding: 0 25px;
}
.paddle-operator-gird-carousel-other button {
  background: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  height: 28px;
  min-width: 80px;
  outline: none;
  padding: 0 12px;
}
.paddle-operator-gird-carousel-background-video {
  opacity: 0;
}
.paddle-operator-gird-carousel-item:hover .paddle-operator-gird-carousel-background-video {
  opacity: 1;
}
.paddle-operator-gird-item-list {
  margin-top: 54px;
}
.paddle-operator-gird-item {
  border-radius: 2px;
  border: 1px solid #dadcdf;
  height: 160px;
  padding: 30px 25px 15px 25px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-operator-gird-item:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
.paddle-operator-gird-item-video {
  cursor: pointer;
}
.paddle-operator-gird-item-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}
.paddle-operator-gird-item-background-video {
  opacity: 0;
}
.paddle-operator-gird-item-video:hover .paddle-operator-gird-item-background-video {
  opacity: 1;
}
.paddle-operator-gird-item-title {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 1.5;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  white-space: nowrap;
}
.paddle-operator-gird-item-title .anticon {
  margin-left: 10px;
}
.paddle-operator-gird-item-play-icon {
  background-image: url(./components/operator-gird/image/play.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  margin: 3px 3px 3px 10px;
  height: 18px;
  vertical-align: top;
  width: 18px;
}
.paddle-operator-gird-item:hover .paddle-operator-gird-item-title {
  color: #2932e1;
}
.paddle-operator-gird-item-text {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  height: 60px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-operator-gird-item-video:hover .paddle-operator-gird-item-text,
.paddle-operator-gird-item-video:hover .paddle-operator-gird-item-title {
  opacity: 0;
}
.paddle-operator-gird-item-extra {
  bottom: 0;
  color: #666;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  padding: 10px 25px 15px 25px;
  position: absolute;
  right: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-operator-gird-item:hover .paddle-operator-gird-item-extra {
  color: #2932e1;
}
.paddle-operator-gird-item-video:hover .paddle-operator-gird-item-extra {
  opacity: 0;
}
/**
 * @file paddle 首页 图标文字列表 在线体验
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-index-icon-text-list {
  margin-top: 35px;
}
.paddle-index-icon-text-list-item {
  background: #fff;
  border: 1px solid #dadcdf;
  border-radius: 2px;
  display: block;
  padding: 40px;
}
.paddle-index-icon-text-list-item-title {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-right: 60px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
a.paddle-index-icon-text-list-item:hover .paddle-index-icon-text-list-item-title {
  color: #2932e1;
}
.paddle-index-icon-text-list-item-tag {
  color: #666;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
  margin-right: 60px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
a.paddle-index-icon-text-list-item:hover .paddle-index-icon-text-list-item-tag {
  color: #2932e1;
}
.paddle-index-icon-text-list-item-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  float: right;
  height: 60px;
  margin-top: 3px;
  margin-right: -5px;
  width: 60px;
}
.paddle-index-icon-text-list-item + .paddle-index-icon-text-list-item {
  margin-top: 15px;
}
/**
 * @file 项目实践
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-application-card-group-wrap {
  height: 353px;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}
.paddle-application-card-group {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-application-card {
  background-color: #fff;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-application-card:hover {
  box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
}
.paddle-application-card-grid {
  display: inline-block;
  vertical-align: top;
}
.paddle-application-card-img {
  background-color: #ebebeb;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 160px;
  overflow: hidden;
}
.paddle-application-card-img img {
  opacity: 0;
  width: 100%;
}
.paddle-application-card-contain {
  border: 1px solid #d8d8d8;
  border-top: 0;
  padding: 30px 40px 25px 40px;
  position: relative;
}
.paddle-application-card-extra {
  position: absolute;
  right: 40px;
  top: 33px;
}
.paddle-application-card-extra-tag {
  background: #eee;
  border-radius: 999999px;
  color: #666;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  min-width: 50px;
  padding: 0 13px;
  text-align: center;
}
.paddle-application-card-grid + .paddle-application-card-grid .paddle-application-card-contain {
  margin-left: -1px;
}
.paddle-application-card-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  height: 30px;
  line-height: 28px;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-application-card:hover .paddle-application-card-title {
  color: #2932E1;
}
.paddle-application-card-text {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
  position: relative;
  text-align: justify;
}
.paddle-application-card:hover .paddle-application-card-text-ellipsis {
  display: none;
}
.paddle-application-card-text-inner {
  height: 70px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-application-card-tag-group {
  color: #b0b0b0;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 20px;
  min-height: 21px;
}
.paddle-application-card > a[href] .paddle-application-card-tag-group {
  color: #2932E1;
}
/**
 * @file paddle 首页 图标文字列表
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-index-icon-text-card {
  margin: 30px -10px -10px -10px;
}
.paddle-index-icon-text-card-item-grid {
  padding: 10px;
}
.paddle-index-icon-text-card-item {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  display: block;
  height: 143px;
  padding: 43px 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-icon-text-card-item:hover {
  background: #fff;
  border-color: #dadcdf;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
.paddle-index-icon-text-card-item-title {
  color: #1f2024;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  margin-right: 42px;
}
.paddle-index-icon-text-card-item:hover .paddle-index-icon-text-card-item-title {
  color: #1434c1;
}
.paddle-index-icon-text-card-item-text {
  color: #666;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 1.5;
  margin-top: 5px;
  margin-right: 42px;
}
.paddle-index-icon-text-card-item-icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  float: right;
  height: 45px;
  margin-top: 3px;
  margin-right: -5px;
  width: 45px;
}
.paddle-index-icon-text-card-item-grid.ant-col-xs-6 .paddle-index-icon-text-card-item {
  padding: 43px 35px;
}
/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-index-product-list {
  border: 1px solid #dadcdf;
  border-radius: 2px;
  margin-top: 40px;
}
.paddle-index-product-list-group {
  display: flex;
  align-items: center;
}
.paddle-index-product-list-group + .paddle-index-product-list-group {
  border-top: 1px solid #dadcdf;
}
.paddle-index-product-list-group-title {
  flex: none;
  display: inline-block;
  float: left;
  height: 120px;
  padding-left: 50px;
  width: 190px;
}
.paddle-index-product-list-group-title:after {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  overflow: hidden;
  width: 0;
}
.paddle-index-product-list-group-title span {
  color: #000;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  vertical-align: middle;
}
.paddle-index-product-list-group-list {
  flex: auto;
}
.paddle-index-product-list-item {
  background: #fff;
  height: 120px;
  padding: 30px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-product-list-item-wrap {
  border-top: 1px solid #dadcdf;
  border-left: 1px solid #dadcdf;
  /* 每行第一个单元格：去掉左边框 */
  /* 第一行：去掉上边框 */
}
.paddle-index-product-list-item-wrap:nth-child(-n + 3) {
  border-top: none;
}
.paddle-index-product-list-item-wrap.ant-col-xs-24 {
  text-align: center;
}
.paddle-index-product-list-item-title {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-product-list-item-title .anticon {
  color: transparent;
  float: right;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-product-list-item-text {
  padding-right: 18px;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 5px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-product-list-item-tag {
  border: 1px solid #1434c1;
  border-radius: 999999px;
  color: #1434c1;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 2px;
  margin-left: 15px;
  padding: 0 10px;
  vertical-align: middle;
}
.paddle-index-product-list-item-tag-new {
  border-color: #00b359;
  color: #00b359;
}
.paddle-index-product-list-item-tag-hot {
  border-color: #ff3812;
  color: #ff3812;
}
.paddle-index-product-list-item-tag-test {
  border-color: #1434c1;
  color: #1434c1;
}
.paddle-index-product-list-item:hover {
  background: #1434c1;
}
.paddle-index-product-list-item:hover .paddle-index-product-list-item-title {
  color: #fff;
}
.paddle-index-product-list-item:hover .paddle-index-product-list-item-title .anticon {
  color: #fff;
}
.paddle-index-product-list-item:hover .paddle-index-product-list-item-text {
  color: #fff;
}
/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-news {
  margin-top: 40px;
}
.paddle-news-carousel-wrap {
  height: 352px;
}
.paddle-news-carousel .slick-slide {
  height: 352px !important;
  overflow: hidden;
  position: relative;
}
.paddle-news-carousel-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.paddle-news-carousel-content {
  bottom: 30px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-news-carousel-info {
  bottom: 24px;
  color: #fefdfd;
  left: 0;
  position: absolute;
  right: 0;
}
.paddle-news-carousel-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 32px;
  margin-right: 140px;
  padding-left: 40px;
  text-align: justify;
}
.paddle-news-carousel-time {
  float: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  padding: 0 40px 0 30px;
}
.paddle-news-carousel-btns {
  margin-top: 15px;
  padding: 0 40px;
}
.paddle-news-carousel-btn,
.paddle-news-carousel-btn:hover {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  padding: 0 12px;
}
.paddle-news-news-wrap {
  background: #1434c2;
  color: #fff;
  height: 352px;
  padding: 48px 36px 48px 54px;
}
.paddle-news-news-inner {
  height: 256px;
  overflow-y: auto;
}
.paddle-news-news-inner::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  height: 4px;
  width: 4px;
}
.paddle-news-news-inner::-webkit-scrollbar-button {
  display: none;
}
.paddle-news-news-inner::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 4px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 4px;
}
.paddle-news-news-inner::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5);
}
.paddle-news-news-item:before {
  background: #fff;
  content: '';
  display: inline-block;
  float: left;
  height: 4px;
  margin: 9px 12px 9px 0;
  vertical-align: middle;
  width: 4px;
}
.paddle-news-news-item-title {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 16px;
  margin-right: 143px;
  text-align: justify;
}
.paddle-news-news-item-time {
  float: right;
  font-weight: 400;
  font-size: 12px;
  color: #ddd;
  line-height: 22px;
  min-width: 87px;
}
.paddle-news-news-item + .paddle-news-news-item {
  margin-top: 30px;
}
/**
 * @file paddle 首页 视频列表
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-index-video-list {
  margin-top: 40px;
  min-height: 185px;
}
.paddle-index-video-list-item {
  border-radius: 2px;
  cursor: pointer;
  height: 185px;
  position: relative;
}
.paddle-index-video-list-item-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.paddle-index-video-list-item-background-mask {
  background: rgba(0, 0, 0, 0.2);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%, #000 100%);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1;
}
.paddle-index-video-list-item:hover .paddle-index-video-list-item-background-mask {
  opacity: 0;
}
.paddle-index-video-list-item-content {
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 30px;
  position: absolute;
  right: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-video-list-item:hover .paddle-index-video-list-item-content {
  opacity: 0;
}
.paddle-index-video-list-item-title {
  color: #fefdfd;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.paddle-index-video-list-item-tag {
  color: #fefdfd;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.paddle-index-video-list-item-tag .anticon {
  margin-left: 8px;
}
.paddle-index-video-list-item .paddle-video-cover {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-video-list-item:hover .paddle-video-cover {
  opacity: 1;
}
/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-index-partner {
  margin-top: 40px;
}
.paddle-index-partner-left {
  display: inline-block;
  float: left;
  width: 399px;
}
.paddle-index-partner-card {
  height: 94px;
}
.paddle-index-partner-card + .paddle-index-partner-card {
  margin-top: 20px;
}
.paddle-index-partner-card-title {
  color: #0b0504;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
.paddle-index-partner-card-text {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 9px;
  text-align: justify;
}
.paddle-index-partner-list {
  margin: 0 -20px -20px 488px;
}
.paddle-index-partner-item {
  border: 1px solid #dadcdf;
  border-radius: 2px;
  height: 94px;
  margin: 0 20px 20px 0;
  padding: 24px 20px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.paddle-index-partner-item:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
.paddle-index-partner-item-background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}
/**
 * @file 时间线
 * @author FengGuang(fengguang01@baidu.com)
 *
 */
.paddle-index-timeline {
  padding: 80px 0 0 0;
}
.paddle-index-timeline-main {
  float: left;
}
.paddle-index-timeline-title {
  color: #fefdfd;
  font-size: 38px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
}
.paddle-index-timeline-text {
  font-size: 18px;
  font-weight: 400;
  color: #fefdfd;
  line-height: 28px;
  margin-top: 25px;
}
.paddle-index-timeline-btns {
  margin-top: 25px;
}
.paddle-index-timeline-btn,
.paddle-index-timeline-btn:focus {
  background: transparent;
  border: 2px solid #fff;
  border-radius: 0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  letter-spacing: 1.8px;
  line-height: 20px;
  text-align: center;
  width: 146px;
}
.paddle-index-timeline-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid #fff;
  color: #fff;
}
.paddle-index-timeline-btn:active {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #d0d0d0;
  color: #d0d0d0;
}
.paddle-index-timeline-btns > * {
  margin-left: 16px;
}
.paddle-index-timeline-btns > *:first-child {
  margin-left: 0;
}
.paddle-index-timeline-numbers {
  float: right;
}
.paddle-index-timeline-number {
  color: #fefdfd;
  display: inline-block;
  text-align: right;
}
.paddle-index-timeline-number-number {
  font-size: 47px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 54px;
}
.paddle-index-timeline-number-text {
  font-size: 24px;
  line-height: 24px;
  margin-top: 14px;
}
.paddle-index-timeline-number + .paddle-index-timeline-number {
  margin-left: 98px;
}
.paddle-index-timeline-timeline {
  display: flex;
  margin-top: 84px;
  margin-right: -45px;
}
.paddle-index-timeline-timeline-item {
  flex: 2 1 auto;
  width: 140px;
}
.paddle-index-timeline-timeline-item-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
}
.paddle-index-timeline-timeline-item-line {
  margin-top: 22px;
}
.paddle-index-timeline-timeline-item-line-point {
  background: #7e85e5;
  border-radius: 999999px;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
}
.paddle-index-timeline-timeline-item-line-dotted-line {
  border-bottom: 1px dotted #fff;
  height: 5px;
}
.paddle-index-timeline-timeline-item:last-of-type .paddle-index-timeline-timeline-item-line-point {
  background: #fff;
  border: 4px solid #7e85e5;
  height: 18px;
  margin-top: -4px;
  width: 18px;
}
.paddle-index-timeline-timeline-item-text {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 31px;
  width: 110px;
}
.paddle-index-timeline-timeline-item:last-of-type .paddle-index-timeline-timeline-item-line-dotted-line {
  width: 110px;
}
.paddle-index-content {
  max-width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 70px;
}
.paddle-index-context-wrap {
  position: relative;
}
.paddle-index-context-min-h2 {
  color: #000;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 45px;
  margin-top: 54px;
}
.paddle-index-context-min-h3 {
  color: #666;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: 10px;
}
.paddle-index-context-min-h3 a {
  color: #2932E1;
  margin-left: 8px;
}
.paddle-index-context-min-h4 {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: 10px;
}
.paddle-index-context-min-h4 a {
  color: #2932E1;
  margin-left: 8px;
}
.paddle-index-context-link-more {
  line-height: 45px;
  position: absolute;
  right: 0;
  top: 0;
}
.paddle-index-context-link-more a {
  font-size: 18px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0;
}
.paddle-index-context-link-more a:hover,
.paddle-index-context-link-more a:active,
.paddle-index-context-link-more a:focus {
  color: #2932E1;
}
.paddle-index-context-link-more .anticon-right {
  font-size: 16px;
  transform: scale(0.82, 0.98);
}
.paddle-index-left {
  margin-right: 403px;
}
.paddle-index-right {
  display: inline-block;
  float: right;
  margin-top: -54px;
  max-width: 374px;
}
.paddle-index-timeline-wrap {
  background: #0011c6;
  background: linear-gradient(0deg, #0011c6 0%, #2932e1 100%);
  color: #fff;
}
