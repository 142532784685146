.paddle-home-introduce {
    display: flex;

    &-card {
        width: 20%;
        padding: 12px 24px;
        margin-right: 16px;
        cursor: pointer;
        
        &:hover {
            box-shadow: 4px 0px 6px 4px rgba(0, 0, 0, 0.05);
        }

        &:last-child {
            margin-right: 0;
        }

        &-title {
            margin-bottom: 12px;
            font-size: 18px;
            color: #000;
        }

        &-desc {
            font-size: 14px;
            line-height: 28px;
            color: #565772;
        }
    }
}