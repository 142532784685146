/**
 * @file 右侧导航条样式
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

.paddle-right-nav {
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    right: 0;
    top: 50%;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 1000;

    &-item {
        cursor: pointer;
        height: 56px;
        position: relative;
        width: 50px;

        &-background {
            background-repeat: no-repeat;
            background-size: 24px auto;
            background-position: center center;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            z-index: 1;
        }

        &-context {
            color: #000;
            font-size: 13px;
            font-weight: 400;
            bottom: 0;
            left: 0;
            letter-spacing: 1px;
            line-height: 16px;
            opacity: 0;
            padding: 13px 10px;
            position: absolute;
            right: 0;
            text-align: justify;
            top: 0;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            z-index: 2;
        }

        &-havecontent:hover &-background {
            opacity: 0;
        }

        &-havecontent:hover &-context {
            opacity: 1;
        }

        &:hover {
            background-color: #f7f7f7;
        }

        &-consultation &-background {
            background-image: url(./image/mailbox.png);
        }

        &-qrcode &-background {
            background-image: url(./image/qrcodeicon.png);
        }

        &-top &-background {
            background-image: url(./image/up.png);
            background-size: 17px auto;
        }

        &-top {
            height: 0;
            overflow: hidden;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        &-top-show {
            height: 56px;
        }

        &-qrcode {
            &-title {
                color: #000;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0;
                padding: 10px 0 8px 0;
                text-align: center;
            }

            &-qrcode {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                border: 1px solid #f0f0f0;
                height: 148px;
                width: 148px;
            }

            // &-qrcode-group {
            //     background-image: url(../../images/qqqr.png);
            // }

            // &-qrcode-subscription {
            //     background-image: url(../../images/paddlepaddleQr.jpg);
            // }
        }
    }
}

.paddle-right-nav-item-qrcode-overlay {
    .ant-popover-inner-content {
        padding: 0 6px 10px 6px;
    }
}
