/**
 * @file paddle 首页 图标文字列表 在线体验
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-index-icon-text-list {
    margin-top: 35px;

    &-item {
        background: #fff;
        border: 1px solid #dadcdf;
        border-radius: 2px;
        display: block;
        padding: 40px;

        &-title {
            color: #000;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 32px;
            margin-right: 60px;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
        }

        a&:hover &-title {
            color: #2932e1;
        }

        &-tag {
            color: #666;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 22px;
            margin-top: 10px;
            margin-right: 60px;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
        }

        a&:hover &-tag {
            color: #2932e1;
        }

        &-icon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            float: right;
            height: 60px;
            margin-top: 3px;
            margin-right: -5px;
            width: 60px;
        }
    }

    &-item + &-item {
        margin-top: 15px;
    }
}
