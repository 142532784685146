/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

.paddle-index-product-list {
    border: 1px solid #dadcdf;
    border-radius: 2px;
    margin-top: 40px;

    &-group {
        display: flex;
        align-items: center;

        & + & {
            border-top: 1px solid #dadcdf;
        }

        &-title {
            flex: none;
            display: inline-block;
            float: left;
            height: 120px;
            padding-left: 50px;
            width: 190px;

            &:after {
                content: '';
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                overflow: hidden;
                width: 0;
            }

            span {
                color: #000;
                display: inline-block;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 26px;
                vertical-align: middle;
            }
        }

        &-list {
            flex: auto;
            // margin-left: 190px;
        }
    }
}

.paddle-index-product-list-item {
    background: #fff;
    height: 120px;
    padding: 30px;
    transition: all 0.3s cubic-bezier(.645, .045, .355, 1);

   

    &-wrap {
        border-top: 1px solid #dadcdf;
        border-left: 1px solid #dadcdf;

        /* 每行第一个单元格：去掉左边框 */
        // &:nth-child(3n + 1) {
        //     border-left: none;
        // }

        /* 第一行：去掉上边框 */
        &:nth-child(-n + 3) {
            border-top: none;
        }

        &.ant-col-xs-24 {
            text-align: center;
        }
    }

    &-title {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 28px;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);

        .anticon {
            color: transparent;
            float: right;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
        }
    }

    &-text {
        padding-right: 18px;
        color: #999;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 5px;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
    }

    &-tag {
        border: 1px solid #1434c1;
        border-radius: 999999px;
        color: #1434c1;
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 2px;
        margin-left: 15px;
        padding: 0 10px;
        vertical-align: middle;

        &-new {
            border-color: #00b359;
            color: #00b359;
        }

        &-hot {
            border-color: #ff3812;
            color: #ff3812;
        }

        &-test {
            border-color: #1434c1;
            color: #1434c1;
        }
    }

    &:hover {
        background: #1434c1;
    }

    &:hover &-title {
        color: #fff;

        .anticon {
            color: #fff;
        }
    }

    &:hover &-text {
        color: #fff;
    }
}
