/**
 * @file paddle 首页 图标文字列表
 * @author FengGuang(fengguang01@baidu.com)
 */

.paddle-index-icon-text-card {
    margin: 30px -10px -10px -10px;

    &-item-grid {
        padding: 10px;
    }

    &-item {
        background: #f5f5f5;
        border: 1px solid #f5f5f5;
        border-radius: 2px;
        display: block;
        height: 143px;
        padding: 43px 50px;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);

        &:hover {
            background: #fff;
            border-color: #dadcdf;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
        }

        &-title {
            color: #1f2024;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 28px;
            margin-right: 42px;
        }

        &:hover &-title {
            color: #1434c1;
        }

        &-text {
            color: #666;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1.5;
            margin-top: 5px;
            margin-right: 42px;
        }

        &-icon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            float: right;
            height: 45px;
            margin-top: 3px;
            margin-right: -5px;
            width: 45px;
        }
    }

    &-item-grid.ant-col-xs-6 &-item {
        padding: 43px 35px;
    }
}
