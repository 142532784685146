/**
 * @file 首页banner样式
 */

.paddle-home-banner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 200px;
    max-width: 1400px;
    margin: 0 auto;

    &-wrap {
        margin-bottom: 32px;
        width: 100%;
        height: 350px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        @media screen and (min-width: 768px) {
            height: 450px;
        }
    }

    &-title {
        font-size: 45px;
        line-height: 56px;
        font-weight: 500;
        color: #2932E1;
        letter-spacing: 1px;
    }

    &-subtitle {
        margin-top: 10px;
        color: #140e35;
        font-size: 18px;
        letter-spacing: 4px;
    }

    &-btn {
        display: inline-block;
        margin-top: 28px;
        height: 40px;
        padding: 4px 24px;
        font-size: 17px;
        line-height: 32px;
        color: #fff;
        background: rgba(70, 88, 255, 0.9);

        &:hover {
            color: #fff;
            background: rgba(70, 88, 255, 1);
        }
    }
}