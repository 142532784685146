/**
 * @file 产品全景
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

.paddle-news {
    margin-top: 40px;

    &-carousel-wrap {
        height: 352px;
    }

    &-carousel {
        .slick-slide {
            height: 352px !important;
            overflow: hidden;
            position: relative;
        }

        &-background {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }

        &-content {
            bottom: 30px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        &-info {
            bottom: 24px;
            color: #fefdfd;
            left: 0;
            position: absolute;
            right: 0;
        }

        &-title {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 32px;
            margin-right: 140px;
            padding-left: 40px;
            text-align: justify;
        }

        &-time {
            float: right;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0;
            padding: 0 40px 0 30px;
        }

        &-btns {
            margin-top: 15px;
            padding: 0 40px;
        }

        &-btn,
        &-btn:hover {
            background: transparent;
            border: 1px solid #fff;
            border-radius: 0;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            height: 28px;
            padding: 0 12px;
        }
    }

    &-news-wrap {
        background: #1434c2;
        color: #fff;
        height: 352px;
        padding: 48px 36px 48px 54px;
    }

    &-news-inner {
        height: 256px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background: rgba(255, 255, 255, .1);
            border-radius: 6px;
            height: 4px;
            width: 4px;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, .2);
            border-radius: 4px;
            height: 4px;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
            width: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: rgba(255, 255, 255, .5);
        }
    }

    &-news-item {
        &:before {
            background: #fff;
            content: '';
            display: inline-block;
            float: left;
            height: 4px;
            margin: 9px 12px 9px 0;
            vertical-align: middle;
            width: 4px;
        }

        &-title {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            margin-left: 16px;
            margin-right: 143px;
            text-align: justify;
        }

        &-time {
            float: right;
            font-weight: 400;
            font-size: 12px;
            color: #ddd;
            line-height: 22px;
            min-width: 87px;
        }
    }

    &-news-item + &-news-item {
        margin-top: 30px;
    }
}
