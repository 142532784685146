/**
 * @file 首页 运营位样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../paddle-carousel/style';
@import '../../../../components/vertical-ellipsis/style.css';
@import '../../../../components/video-cover/style';
@import '../../../../components/video-modal/style';

.paddle-operator-gird {
    &-carousel-wrap {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
        border-radius: 2px;
        display: inline-block;
        float: left;
        height: 330px;
        width: 270px;
    }

    &-carousel {
        .slick-slide {
            height: 330px;
            overflow: hidden;
            position: relative;
        }

        &-link {
            cursor: pointer;
        }

        &-content {
            bottom: 30px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        &-background {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }

        &-title {
            color: #000;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 28px;
            margin-top: 34px;
            padding: 0 25px;
        }

        &-text {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            letter-spacing: 0;
            line-height: 28px;
            padding: 0 25px;
        }

        &-other {
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 20px;
            padding: 0 25px;

            button {
                background: transparent;
                border: 1px solid #fff;
                cursor: pointer;
                height: 28px;
                min-width: 80px;
                outline: none;
                padding: 0 12px;
            }
        }

        &-background-video {
            opacity: 0;
        }

        &-item:hover &-background-video {
            opacity: 1;
        }
    }


    &-item-list {
        // margin-left: 280px;
        margin-top: 54px;
    }

    &-item {
        border-radius: 2px;
        border: 1px solid #dadcdf;
        height: 160px;
        padding: 30px 25px 15px 25px;
        position: relative;
        transition: all 0.3s cubic-bezier(.645, .045, .355, 1);

        &:hover {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .05);
        }

        &-video {
            cursor: pointer;
        }

        &-background {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            bottom: 0;
            left: 0;
            overflow: hidden;
            position: absolute;
            right: 0;
            top: 0;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
            z-index: -1;
        }

        &-background-video {
            opacity: 0;
        }

        &-video:hover &-background-video {
            opacity: 1;
        }

        &-title {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.32px;
            line-height: 1.5;
            overflow: hidden;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
            white-space: nowrap;

            .anticon {
                margin-left: 10px;
            }
        }

        &-play-icon {
            background-image: url(./image/play.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            display: inline-block;
            margin: 3px 3px 3px 10px;
            height: 18px;
            vertical-align: top;
            width: 18px;
        }

        &:hover &-title {
            color: #2932e1;
        }

        &-text {
            font-size: 12px;
            font-weight: 400;
            color: #000;
            height: 60px;
            letter-spacing: 0;
            line-height: 20px;
            margin-top: 8px;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
        }

        &-video:hover &-text,
        &-video:hover &-title {
            opacity: 0;
        }

        &-extra {
            bottom: 0;
            color: #666;
            font-size: 14px;
            font-weight: 400;
            left: 0;
            letter-spacing: 0;
            line-height: 22px;
            padding: 10px 25px 15px 25px;
            position: absolute;
            right: 0;
            transition: all 0.3s cubic-bezier(.645, .045, .355, 1);
        }

        &:hover &-extra {
            color: #2932e1;
        }

        &-video:hover &-extra {
            opacity: 0;
        }
    }
}
